const {
  wixCodeItemToProGallery,
  proGalleryItemToWixCode
} = require('@wix/pro-gallery-items-formatter')

module.exports = ({ uploadedFileUrl, mediaItemUtils }) => {
  const mediaItem = mediaItemUtils.parseMediaItemUri(uploadedFileUrl)
  const mediaItemUri = mediaItemUtils.createMediaItemUri(mediaItem).item
  const proGalleryItem = wixCodeItemToProGallery(
    Object.assign(mediaItem, { src: mediaItemUri })
  )

  return proGalleryItemToWixCode(proGalleryItem)
}
