'use strict'
const forEach_ = require('lodash/forEach')
const values_ = require('lodash/values')
const partial_ = require('lodash/partial')
const isEmpty_ = require('lodash/isEmpty')
const { Maybe } = require('@wix/wix-code-adt')

const {
  FieldType: { dateTime: DATETIME, reference: REFERENCE }
} = require('@wix/wix-data-schema-types')

const {
  TIMEPICKER_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')

const { selectCurrentRecord } = require('../../dataset-controller/rootReducer')
const {
  getFieldValue,
  setValueToComponent,
  shouldUpdateComponentFromRecord,
  addComponentEventListener,
  getLogVerboseBindingDescription
} = require('./helpers')
const baseAdapter = require('./baseAdapter')

const {
  isTimeValid,
  isDateValid,
  mergeDateWithTime,
  getTimeFromDate
} = require('../../helpers/dateTimeUtils')

module.exports = ({
  getState,
  errorReporter,
  getFieldType,
  applicationCodeZone,
  databindingVerboseReporter
}) => {
  const setFieldOnComponentChange = (component, properties, actions) => {
    const getTodayAtMidnightDate = value => {
      const newDate = new Date()
      newDate.setHours(0)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      newDate.setMilliseconds(0)

      return newDate
    }

    const getValidDate = value => {
      const validDateCandidate = new Date(value)
      return isDateValid(validDateCandidate)
        ? validDateCandidate
        : getTodayAtMidnightDate()
    }

    const convertValueBeforeSetInRecord = (
      newValue,
      currentValue,
      fieldName,
      fieldType
    ) => {
      switch (fieldType) {
        case REFERENCE:
          return actions.fetchRecordById(
            newValue, // referenced record id
            fieldName
          )
        case DATETIME:
          if (newValue) {
            const destructuredCurrentValue = {}
            const destructuredNewValue = {}
            if (isTimeValid(currentValue)) {
              destructuredCurrentValue.time = currentValue
              destructuredCurrentValue.date = new Date()
            } else {
              const currentDate = getValidDate(currentValue)
              destructuredCurrentValue.date = currentDate
              destructuredCurrentValue.time = getTimeFromDate(currentDate)
            }

            if (isTimeValid(newValue)) {
              destructuredNewValue.time = newValue
            } else {
              destructuredNewValue.date = getValidDate(newValue)
            }

            return Maybe.Just(
              mergeDateWithTime({
                ...destructuredCurrentValue,
                ...destructuredNewValue
              })
            )
          }
          return Maybe.Nothing()
        default:
          return Maybe.Nothing()
      }
    }

    const getValue = (newValue, currentValue, fieldName) =>
      getFieldType(fieldName)
        .chain(
          partial_(
            convertValueBeforeSetInRecord,
            newValue,
            currentValue,
            fieldName
          )
        )
        .getOrElse(newValue)

    addComponentEventListener(
      component,
      'onChange',
      event => {
        const propName = properties.checked ? 'checked' : 'value'
        const fieldName = properties[propName].fieldName
        const record = selectCurrentRecord(getState())
        const value = getValue(
          event.target[propName],
          record[fieldName],
          fieldName
        )

        actions.setFieldInCurrentRecordAndSynchronize(
          fieldName,
          value,
          component.uniqueId
        )
      },
      applicationCodeZone
    )
  }

  const formatInputValue = (value, role) => {
    if (role === TIMEPICKER_ROLE && isDateValid(value)) {
      return getTimeFromDate(value)
    }
    return value
  }

  const updateComponentFromCurrentRecord = (
    { connectionConfig: { properties }, component, role },
    actions,
    updatedFields = []
  ) => {
    const record = selectCurrentRecord(getState())
    if (!record) {
      return
    }
    const valueDescription = {}

    forEach_(properties, ({ fieldName }, propPath) => {
      try {
        const fieldType = getFieldType(fieldName).getOrElse('')
        const formattedValue = formatInputValue(
          getFieldValue(record, fieldName),
          role
        )
        valueDescription[propPath] = formattedValue

        if (shouldUpdateComponentFromRecord({ updatedFields, fieldName })) {
          setValueToComponent({
            component,
            propPath,
            value: formattedValue,
            fieldType
          })
        }
      } catch (e) {
        errorReporter(`Failed setting ${propPath}:`, e)
      }
    })

    databindingVerboseReporter.logValue({
      component,
      valueDescription
    })
  }

  const syncValidityIndication = ({ component }, actions) => {
    const pristine = actions.isCurrentRecordPristine(getState())
    const newRecord = actions.isCurrentRecordNew(getState())
    if (pristine && newRecord) {
      component.resetValidityIndication && component.resetValidityIndication()
    }
  }

  const sync = actions => () => {
    actions.refresh()
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig }) {
      return values_(connectionConfig).find(
        configValue => !isEmpty_(configValue)
      )
    },

    bindToComponent({ connectionConfig, component }, actions) {
      const { properties, filters } = connectionConfig

      setFieldOnComponentChange(component, properties, actions)

      if (filters) {
        addComponentEventListener(
          component,
          'onChange',
          sync(actions),
          applicationCodeZone
        )
      }

      databindingVerboseReporter.logBinding({
        component,
        bindingDescription: getLogVerboseBindingDescription(connectionConfig)
      })
    },

    currentRecordModified(componentAdapterContext, actions, updatedFields) {
      updateComponentFromCurrentRecord(
        componentAdapterContext,
        actions,
        updatedFields
      )
      syncValidityIndication(componentAdapterContext, actions)
    },

    recordSetLoaded(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
      syncValidityIndication(componentAdapterContext, actions)
    },

    currentViewChanged(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
      syncValidityIndication(componentAdapterContext, actions)
    },

    currentIndexChanged(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
      syncValidityIndication(componentAdapterContext, actions)
    }
  }
}
