'use strict'

const { Maybe } = require('@wix/wix-code-adt')
const isPlainObject_ = require('lodash/isPlainObject')

const { resolveDynamicProperties } = require('./dynamicProperties')
const emptyConnectionConfig = require('../helpers/emptyConnectionConfig')
const {
  PAGE_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')

const createAdapterContext = ({ getFieldType, role, component, $w, api }) => {
  const connectionConfig = Maybe.fromNullable(component.connectionConfig)
    .filter(isPlainObject_)
    .map(connectionConfig =>
      resolveDynamicProperties({ connectionConfig }, role, getFieldType)
    )
    .getOrElse(emptyConnectionConfig)

  if (role === PAGE_ROLE) {
    component = $w('Document')
  }

  const adapterContext = {
    role,
    connectionConfig,
    component,
    componentId: component.id,
    api
  }

  return adapterContext
}

module.exports.createAdapterContext = createAdapterContext
