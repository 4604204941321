'use strict'

const { addComponentEventListener } = require('./helpers')
const baseAdapter = require('./baseAdapter')

const adapter = ({
  controllerFactory,
  controllerStore,
  applicationCodeZone
}) => {
  const itemReady = (actions, api, component) => (
    scoped$w,
    itemData,
    index
  ) => {
    const scopeId = { componentId: component.uniqueId, itemId: itemData._id }
    const controller = controllerFactory.createDetailsController(
      scopeId,
      scoped$w.scoped
    )
    controllerStore.setController(scopeId, controller)
    controller.pageReady()
  }

  const itemRemoved = component => itemData => {
    const scopeId = { componentId: component.uniqueId, itemId: itemData._id }
    controllerStore.removeController(scopeId)
  }

  return {
    ...baseAdapter,

    bindToComponent(
      { connectionConfig: { properties, events, filters }, component },
      actions,
      api
    ) {
      addComponentEventListener(
        component,
        'onItemReady',
        itemReady(actions, api, component),
        applicationCodeZone
      )
      addComponentEventListener(
        component,
        'onItemRemoved',
        itemRemoved(component),
        applicationCodeZone
      )
    }
  }
}

module.exports = adapter
