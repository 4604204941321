'use strict'

const uniq_ = require('lodash/uniq')
const get_ = require('lodash/get')
const mapValues_ = require('lodash/mapValues')
const groupBy_ = require('lodash/groupBy')
const flow_ = require('lodash/flow')
const isNull_ = require('lodash/isNull')
const { Maybe } = require('@wix/wix-code-adt')

const {
  UPLOAD_BUTTON_ROLE,
  SIGNATURE_INPUT_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')
const { SCOPE_TYPES } = require('@wix/dbsm-common/src/scopes/consts')

const rootReducer = require('./rootReducer')
const recordActions = require('../records/actions')
const dynamicPagesActions = require('../dynamic-pages/actions')
const configActions = require('../dataset-config/actions')
const rootActions = require('./actions')

const configureDatasetStore = require('./configureStore')
const {
  setDependencies,
  waitForDependencies,
  performHandshake,
  resolveMissingDependencies
} = require('../dependency-resolution/actions')
const datasetApiCreator = require('../dataset-api/datasetApi')
const eventListenersCreator = require('../dataset-events/eventListeners')
const syncComponentsWithState = require('../side-effects/syncComponentsWithState')
const getFieldType = require('../schemas/getFieldType')
const createConnectedComponentsStore = require('../connected-components')
const {
  adapterApiCreator,
  createComponentAdapterContexts,
  createDetailsRepeatersAdapterContexts,
  initAdapters
} = require('../components')
const {
  createFilterResolver,
  createValueResolvers,
  hasDatabindingDependencies,
  getDatabindingDependencyIds,
  hasUserInputDependencies
} = require('../filter-resolvers')
const wixFormattingCreator = require('@wix/wix-code-formatting')
const dependenciesManagerCreator = require('../dependency-resolution/dependenciesManager')
const { isSameRecord, createRecordStoreInstance } = require('../record-store')

const { reportDatasetActiveOnPage } = require('../bi/events')

const rootSubscriber = require('./rootSubscriber')
const dynamicPagesSubscriber = require('../dynamic-pages/subscriber')
const createSiblingDynamicPageUrlGetter = require('../dynamic-pages/siblingDynamicPageGetterFactory')
const seedDataFetcher = require('./seed')
const { traceCreators } = require('../logger')
const generateRecordFromDefaultComponentValues = require('../helpers/generateRecordFromDefaultComponentValues')

const waitForControllerDependencies = store => {
  const filter = rootReducer.getFilter(store.getState())

  if (!hasDatabindingDependencies(filter)) {
    return
  }

  const dependenciesIds = getDatabindingDependencyIds(filter)
  store.dispatch(setDependencies(dependenciesIds))
  return waitForDependencies(store)
}

const onChangeHandler = (getState, dispatch, adapterApi, logger) => {
  const areArgumentsIllegal = (before, after) =>
    isNull_(before) && isNull_(after)
  const recordWasAdded = (before, after) => isNull_(before)
  const recordWasDeleted = (before, after) => isNull_(after)
  const currentRecordWasChanged = (changedRecord, currentRecord) =>
    isSameRecord(changedRecord, currentRecord)

  return (before, after, componentIdToExclude) => {
    const argsAreIllegal = areArgumentsIllegal(before, after)
    if (argsAreIllegal) {
      logger.error(
        new Error('onChangeHandler invoked with illegal arguments'),
        { extra: { arguments: { before, after, componentIdToExclude } } }
      )
      return
    }

    if (recordWasAdded(before, after)) {
      dispatch(recordActions.refreshCurrentView()).catch(() => {})
      return
    }

    const currentRecord = rootReducer.selectCurrentRecord(getState())

    if (recordWasDeleted(before, after)) {
      if (isSameRecord(before, currentRecord)) {
        dispatch(recordActions.refreshCurrentRecord()).catch(() => {})
      }
      dispatch(recordActions.refreshCurrentView()).catch(() => {})
      return
    }

    if (currentRecordWasChanged(before, currentRecord)) {
      const currentRecordIndex = rootReducer.selectCurrentRecordIndex(
        getState()
      )

      dispatch(
        recordActions.setCurrentRecord(
          after,
          currentRecordIndex,
          componentIdToExclude
        )
      ).catch(() => {})
    }
  }
}

function waitForAllChildControllersToBeReady(controllerStore) {
  return Promise.all(
    controllerStore.getAll().map(
      scope =>
        new Promise(resolve => {
          scope.staticExports.onReady(resolve)
        })
    )
  )
}

const createDataset = (controllerFactory, controllerStore) => (
  isScoped,
  isFixedItem,
  {
    $w,
    controllerConfig,
    datasetType,
    connections,
    wixDataProxy,
    wixSdk,
    firePlatformEvent,
    errorReporter,
    verboseReporter,
    routerData,
    appLogger,
    datasetId,
    handshakes = [],
    schemaAPI,
    recordStoreService,
    reportFormEventToAutomation,
    instansiateDatabindingVerboseReporter,
    parentId,
    platformAPIs
  }
) => {
  const {
    findAndSetConnectedComponents,
    resolveHandshakes,
    getConnectedComponents,
    getConnectedComponentIds
  } = createConnectedComponentsStore()
  const unsubscribeHandlers = []
  const eventListeners = eventListenersCreator(
    firePlatformEvent,
    errorReporter,
    verboseReporter
  )

  const { prefetchedData, dynamicPagesData } = routerData || {}

  const { fireEvent } = eventListeners
  unsubscribeHandlers.push(eventListeners.dispose)

  const { store, subscribe, onIdle } = configureDatasetStore(
    appLogger,
    datasetId
  )

  unsubscribeHandlers.push(
    appLogger.addSessionData(() => ({
      [datasetId]: {
        datasetType,
        state: store.getState(),
        connections
      }
    }))
  )

  store.dispatch(
    rootActions.init({ controllerConfig, connections, isScoped, datasetType })
  )

  const {
    datasetIsVirtual,
    datasetIsReal,
    datasetIsDeferred,
    datasetIsWriteOnly,
    datasetCollectionName,
    dynamicPageNavComponentsShouldBeLinked
  } = rootReducer.getDatasetStaticConfig(store.getState())

  unsubscribeHandlers.push(
    appLogger.addSessionData(() => ({ scopes: controllerStore.getAll() }))
  )

  const dependenciesManager = dependenciesManagerCreator()
  unsubscribeHandlers.push(dependenciesManager.unsubscribe)

  const hasFilterBindings = hasUserInputDependencies(
    rootReducer.getFilter(store.getState())
  )

  let dependenciesPromise
  if (!hasFilterBindings) {
    dependenciesPromise = waitForControllerDependencies(store)
  }

  const getSchema = (schemaName = datasetCollectionName) => {
    return Maybe.fromNullable(schemaAPI.getSchema(schemaName))
  }

  const getFieldTypeFunc = fieldName => {
    const schema = getSchema(datasetCollectionName)
    const referencedCollectionsSchemas = schemaAPI.getReferencedCollectionsSchemas(
      datasetCollectionName
    )
    return schema.chain(s =>
      Maybe.fromNullable(
        getFieldType(s, referencedCollectionsSchemas)(fieldName)
      )
    )
  }

  const valueResolvers = createValueResolvers(
    dependenciesManager.get(),
    wixSdk,
    getConnectedComponents,
    getFieldTypeFunc
  )
  const filterResolver = createFilterResolver(valueResolvers)

  const recordStore = createRecordStoreInstance({
    recordStoreService,
    getFilter: flow_(_ => store.getState(), rootReducer.getFilter),
    getSort: flow_(_ => store.getState(), rootReducer.getSort),
    getPageSize: flow_(_ => store.getState(), rootReducer.getCurrentPageSize),
    shouldAllowWixDataAccess: flow_(
      _ => store.getState(),
      rootReducer.shouldAllowWixDataAccess
    ),
    prefetchedData,
    datasetId,
    filterResolver,
    getSchema
  })

  const siblingDynamicPageUrlGetter = dynamicPageNavComponentsShouldBeLinked
    ? createSiblingDynamicPageUrlGetter({
        wixDataProxy,
        dynamicPagesData,
        collectionName: datasetCollectionName
      })
    : null

  if (dynamicPageNavComponentsShouldBeLinked) {
    subscribe(dynamicPagesSubscriber(siblingDynamicPageUrlGetter))
    store.dispatch(dynamicPagesActions.initialize(connections))
  }

  const datasetApi = datasetApiCreator({
    store,
    recordStore,
    logger: appLogger,
    eventListeners,
    handshakes,
    controllerStore,
    errorReporter,
    verboseReporter,
    datasetId,
    datasetType,
    isFixedItem,
    siblingDynamicPageUrlGetter,
    dependenciesManager,
    onIdle,
    getConnectedComponentIds
  })

  const uniqueRoles = uniq_(connections.map(conn => conn.role))
  const appDatasetApi = datasetApi(false)
  const componentAdapterContexts = []
  const databindingVerboseReporter = instansiateDatabindingVerboseReporter(
    datasetCollectionName,
    parentId
  )
  const isSSRMode = get_(wixSdk, ['window', 'rendering', 'env']) === 'backend'
  const isThunderboltRenderer =
    get_(platformAPIs, ['bi', 'viewerName']) === 'thunderbolt'
  const locale = wixSdk.site.regionalSettings || wixSdk.window.browserLocale

  const adapterParams = {
    getState: store.getState,
    datasetApi: appDatasetApi,
    wixSdk,
    errorReporter,
    platformAPIs,
    eventListeners,
    roles: uniqueRoles,
    getFieldType: getFieldTypeFunc,
    getSchema,
    wixDataProxy,
    appLogger,
    applicationCodeZone: appLogger.applicationCodeZone,
    controllerFactory,
    controllerStore,
    databindingVerboseReporter,
    parentId,
    wixFormatter:
      (isSSRMode && !isThunderboltRenderer) || !locale
        ? null
        : wixFormattingCreator({
            locale
          })
  }
  const adapterApi = adapterApiCreator({
    dispatch: store.dispatch,
    recordStore,
    componentAdapterContexts
  })

  unsubscribeHandlers.push(
    recordStoreService
      .map(service =>
        service.onChange(
          onChangeHandler(store.getState, store.dispatch, adapterApi, appLogger)
        )
      )
      .getOrElse(() => {})
  )

  const hasPrefetchedData = !!prefetchedData

  const shouldFetchInitialData = controllerConfig && !datasetIsWriteOnly

  const fetchInitialData = () =>
    shouldFetchInitialData
      ? seedDataFetcher(
          hasPrefetchedData,
          recordStore,
          errorReporter,
          appLogger
        )
      : Promise.resolve(Maybe.Nothing())

  let resolveDeferredDataset
  const getFetchInitialDataPromise = () =>
    datasetIsDeferred
      ? new Promise(resolve => (resolveDeferredDataset = resolve)).then(
          fetchInitialData
        )
      : fetchInitialData()

  let fetchInitialDataPromise
  if (!hasFilterBindings) {
    fetchInitialDataPromise = dependenciesPromise
      ? dependenciesPromise.then(getFetchInitialDataPromise)
      : getFetchInitialDataPromise()
    fetchInitialDataPromise.then(maybeRecord =>
      maybeRecord.map(record =>
        store.dispatch(recordActions.setCurrentRecord(record, 0))
      )
    )
  }

  handshakes.forEach(handshake =>
    performHandshake(dependenciesManager, store.dispatch, handshake)
  )

  const shouldRefreshDataset = () => {
    const currentRecordIndex = rootReducer.selectCurrentRecordIndex(
      store.getState()
    )
    const isPristine = recordStore().fold(
      () => false,
      service => service.isPristine(currentRecordIndex)
    )

    return isPristine && !datasetIsWriteOnly
  }

  const pageReady = async function() {
    wixSdk.user.onLogin(() => {
      // THIS SHOULD HAPPEN SYNCHRONOUSLY SO TESTS WILL REMAIN MEANINGFUL
      // IF YOU EVER FIND THE NEED TO MAKE IT ASYNC - TALK TO leeor@wix.com
      if (shouldRefreshDataset()) {
        appDatasetApi.refresh()
      }
    })

    findAndSetConnectedComponents(uniqueRoles, $w)

    if (hasFilterBindings) {
      dependenciesPromise = waitForControllerDependencies(store)
      fetchInitialDataPromise = dependenciesPromise
        ? dependenciesPromise.then(getFetchInitialDataPromise)
        : getFetchInitialDataPromise()

      fetchInitialDataPromise.then(maybeRecord =>
        maybeRecord.map(record =>
          store.dispatch(recordActions.setCurrentRecord(record, 0))
        )
      )
    }

    // THIS SHOULD HAPPEN SYNCHRONOUSLY AFTER PAGE READY IS CALLED TO KEEP CONTROLLERS RUNNING SEQUENCE
    const controllersToHandshake = resolveHandshakes({
      datasetApi: appDatasetApi,
      components: getConnectedComponents(),
      controllerConfig,
      controllerConfigured: rootReducer.isDatasetConfigured(store.getState())
    })
    controllersToHandshake.forEach(({ controller, handshakeInfo }) =>
      controller.handshake(handshakeInfo)
    )

    if (dependenciesPromise) {
      // if by now we are still waiting for dependencies, mark them as resolved
      // since they are guaranteed to perform a handshake with us before our pageReady.
      // A missing dependency can happen in a master-detail scenario where the user
      // deleted the master dataset
      store.dispatch(resolveMissingDependencies())

      await dependenciesPromise
    }

    const dependencies = dependenciesManager.get()

    // scoped datasets are sure to have the schema resolved and therefore don't have to wait
    if (datasetIsReal) {
      await schemaAPI.waitForSchemas()
    }

    componentAdapterContexts.push(
      ...createComponentAdapterContexts({
        connectedComponents: getConnectedComponents(),
        $w,
        adapterApi,
        getFieldType: getFieldTypeFunc,
        ignoreItemsInRepeater: datasetIsReal,
        dependencies,
        adapterParams
      })
    )

    if (datasetIsReal) {
      //TODO: add additional check by master dataset
      const detailsRepeatersAdapterContexts = createDetailsRepeatersAdapterContexts(
        getConnectedComponents(),
        getFieldTypeFunc,
        dependencies,
        adapterParams
      )
      componentAdapterContexts.push(...detailsRepeatersAdapterContexts)
    }

    subscribe(
      rootSubscriber(
        recordStore,
        adapterApi,
        getFieldTypeFunc,
        eventListeners.executeHooks,
        appLogger,
        datasetId,
        componentAdapterContexts,
        getSchema,
        datasetCollectionName,
        reportFormEventToAutomation,
        fireEvent,
        verboseReporter
      )
    )

    unsubscribeHandlers.push(
      addComponentDataToExceptions(
        componentAdapterContexts,
        appLogger,
        datasetId
      )
    )

    unsubscribeHandlers.push(
      syncComponentsWithState(
        store,
        componentAdapterContexts,
        appLogger,
        datasetId,
        recordStore
      )
    )

    const defaultRecord = generateRecordFromDefaultComponentValues(
      componentAdapterContexts.filter(
        ({ role }) => ![UPLOAD_BUTTON_ROLE, SIGNATURE_INPUT_ROLE].includes(role)
      )
    )

    store.dispatch(recordActions.setDefaultRecord(defaultRecord))
    if (
      rootReducer.isDatasetConfigured(store.getState()) &&
      datasetIsWriteOnly
    ) {
      await store.dispatch(recordActions.initWriteOnly(datasetIsVirtual))
    }

    if (datasetIsDeferred) {
      // we should hide all components connected to deferred dataset before telling the Platform we are ready
      adapterApi().hideComponent({ rememberInitiallyHidden: true })
    }

    const pageReadyResult = fetchInitialDataPromise.then(async () => {
      try {
        reportDatasetActiveOnPage(
          appLogger.bi,
          store.getState(),
          connections,
          datasetType,
          datasetIsVirtual,
          datasetId,
          wixSdk
        )
      } catch (err) {
        appLogger.error(err)
      }
      await initAdapters(adapterApi())
      if (datasetIsReal) {
        await waitForAllChildControllersToBeReady(controllerStore)
      }
      if (datasetIsDeferred) {
        // we should show all components connected to deferred dataset only after all child controllers (repeater items) are ready
        adapterApi().showComponent({ ignoreInitiallyHidden: true })
      }
      store.dispatch(configActions.setIsDatasetReady(true))
      fireEvent('datasetReady')
      return get_(wixSdk, ['window', 'rendering', 'env']) === 'backend'
        ? {
            schemas: await schemaAPI.waitForSchemas(),
            store: recordStore().fold(
              () => null,
              service => service.getTheStore()
            )
          }
        : undefined
    })

    if (datasetIsDeferred) {
      if (!isSSRMode) {
        // we should make wixData requests and handle results for deferred datasets only in client
        resolveDeferredDataset()
      }
      return Promise.resolve()
    }
    return pageReadyResult
  }

  const userCodeDatasetApi = datasetApi(true)
  const dynamicExports = (scope /*, $w*/) => {
    switch (scope.type) {
      case SCOPE_TYPES.COMPONENT:
        return userCodeDatasetApi.inScope(
          scope.compId,
          scope.additionalData.itemId
        )
      default:
        return userCodeDatasetApi
    }
  }

  const dispose = () => {
    componentAdapterContexts.splice(0)
    unsubscribeHandlers.forEach(h => h())
  }

  const finalPageReady = datasetIsVirtual
    ? pageReady
    : () => appLogger.traceAsync(traceCreators.pageReady(), pageReady)

  return {
    pageReady: appLogger.applicationCodeZone(finalPageReady),
    exports: dynamicExports,
    staticExports: userCodeDatasetApi,
    dispose
  }
}

const addComponentDataToExceptions = (
  componentAdapterContexts,
  logger,
  datasetId
) => {
  const componentIdToRole = mapValues_(
    groupBy_(componentAdapterContexts, cac => cac.component.id),
    cacArray => cacArray.map(cac => cac.role).join()
  )

  return logger.addSessionData(() => ({
    [datasetId]: {
      components: componentIdToRole
    }
  }))
}

module.exports = createDataset
