const baseAdapter = require('./baseAdapter')
const { convertValueToString } = require('./helpers')

const adapter = ({ databindingVerboseReporter }) => {
  const updateComponent = async (
    {
      connectionConfig: {
        properties: {
          options: { fieldName }
        }
      },
      component
    },
    actions
  ) => {
    const { items } = await actions.fetchAll()
    const options = items.reduce((acc, record) => {
      const value = convertValueToString(record[fieldName])

      if (value) acc.push({ value, label: value })

      return acc
    }, [])

    component.options = options

    databindingVerboseReporter.logValue({
      component,
      valueDescription: { options }
    })
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig: { properties = {} } }) {
      return Boolean(Object.keys(properties).length)
    },

    bindToComponent({
      component,
      connectionConfig: {
        properties: {
          options: { fieldName }
        }
      }
    }) {
      databindingVerboseReporter.logBinding({
        component,
        bindingDescription: { options: fieldName }
      })
    },

    currentRecordModified: updateComponent,
    recordSetLoaded: updateComponent
  }
}

module.exports = adapter
