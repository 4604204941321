const isModePreview = viewMode => viewMode === 'Preview'
const isModeEditor = viewMode => viewMode === 'Editor'
const isEnvLive = viewMode => viewMode === 'Site'
const isEnvEditor = viewMode =>
  isModePreview(viewMode) || isModeEditor(viewMode)

module.exports = {
  isModePreview,
  isModeEditor,
  isEnvLive,
  isEnvEditor
}
