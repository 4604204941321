'use strict'

const isEmpty_ = require('lodash/isEmpty')
const forEach_ = require('lodash/forEach')
const orderBy_ = require('lodash/orderBy')

const { convertValueToString, getFieldValue } = require('./helpers')
const {
  getFieldReferencedCollection,
  getSchemaDisplayField
} = require('../../schemas/helpers')
const baseAdapter = require('./baseAdapter')

const adapter = ({
  getState,
  getFieldType,
  getSchema,
  wixDataProxy,
  databindingVerboseReporter
}) => {
  const createOption = (record, fieldName) => ({
    value: convertValueToString(getFieldValue(record, fieldName)),
    label: convertValueToString(getFieldValue(record, fieldName)) || ''
  })

  const createOptionForReference = (record, displayField, fieldName) => ({
    value: convertValueToString(record._id),
    label: convertValueToString(record[displayField])
  })

  const fetchDropdownOptions = async (fieldName, actions) => {
    const isReference = getFieldType(fieldName)
      .map(fieldType => fieldType === 'reference')
      .getOrElse(false)

    if (isReference) {
      return getSchema()
        .chain(schema => {
          const refCollection = getFieldReferencedCollection(fieldName, schema)
          return getSchema(refCollection)
            .map(getSchemaDisplayField)
            .map(async displayField => {
              const { items } = await actions.fetchAll(fieldName)

              const options = orderBy_(
                items.map(record =>
                  createOptionForReference(record, displayField, fieldName)
                ),
                [option => option.label.toLowerCase()]
              )

              return options
            })
        })
        .getOrElse(Promise.resolve([]))
    } else {
      const { items } = await actions.fetchAll()

      return items.map(record => createOption(record, fieldName))
    }
  }

  const handleSingleEmptyOption = options => {
    const firstOption = options[0]
    if (
      options.length === 1 &&
      firstOption.label === '' &&
      firstOption.value === ''
    ) {
      return []
    }
    return options
  }

  const getDropdownOptions = async (fieldName, actions) =>
    handleSingleEmptyOption(await fetchDropdownOptions(fieldName, actions))

  const updateComponent = async (
    { connectionConfig: { properties }, component },
    actions
  ) => {
    // to allow casting component to any
    // prettier-ignore
    const dropdown = (component)
    if (properties.value && properties.value.fieldName) {
      const options = await getDropdownOptions(
        properties.value.fieldName,
        actions
      )
      databindingVerboseReporter.logValue({
        component,
        valueDescription: { options }
      })
      dropdown.options = options
    }
  }

  const logVerboseForBinding = (component, connectionConfig) => {
    const { properties } = connectionConfig
    const bindingDescription = {}

    forEach_(properties, ({ fieldName }) => {
      bindingDescription.options = fieldName
    })

    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig: { properties } }) {
      return !isEmpty_(properties)
    },

    bindToComponent({ component, connectionConfig }) {
      logVerboseForBinding(component, connectionConfig)
    },

    currentRecordModified: updateComponent,
    recordSetLoaded: updateComponent
  }
}

module.exports = adapter
