'use strict'

const {
  DETAILS_DATASET_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')

module.exports = () => {
  let connectedComponents

  const findAndSetConnectedComponents = (roles, $w) => {
    connectedComponents = []
    roles.forEach(role => {
      const components = $w('@' + role)
      components &&
        components.forEach(
          component =>
            component && connectedComponents.push({ role, component })
        )
    })
  }

  const getConnectedComponents = () => connectedComponents

  const getConnectedComponentIds = () =>
    connectedComponents.map(({ component }) => component.uniqueId)

  const resolveHandshakes = ({
    datasetApi,
    components,
    controllerConfig,
    controllerConfigured
  }) =>
    components
      .filter(({ role }) => role === DETAILS_DATASET_ROLE)
      .map(({ component, role }) => ({
        controller: component,
        handshakeInfo: {
          controllerApi: datasetApi,
          controllerConfig,
          controllerConfigured,
          connectionConfig: component.connectionConfig,
          role: DETAILS_DATASET_ROLE
        }
      }))

  return {
    findAndSetConnectedComponents,
    resolveHandshakes,
    getConnectedComponents,
    getConnectedComponentIds
  }
}
