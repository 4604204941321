'use strict'

const SCOPE_TYPES = {
  PRIMARY: 'PRIMARY',
  DETAILS: 'DETAILS',
  OTHER: 'OTHER'
}

const getScopeType = (repeaterId, dependencies, connectedComponents) => {
  if (
    connectedComponents.some(
      ({ component }) => component.uniqueId === repeaterId
    )
  ) {
    return SCOPE_TYPES.PRIMARY
  }

  const isOneOfMyMastersConnectedToRepeater = Object.values(dependencies).some(
    dependency =>
      dependency.controllerApi &&
      dependency.controllerApi.isConnectedToComponent &&
      dependency.controllerApi.isConnectedToComponent(repeaterId)
  )

  if (isOneOfMyMastersConnectedToRepeater) {
    return SCOPE_TYPES.DETAILS
  }

  return SCOPE_TYPES.OTHER
}

module.exports.SCOPE_TYPES = SCOPE_TYPES
module.exports.getScopeType = getScopeType
