'use strict'

const isTimeValid = value => /^\d{2}:\d{2}:\d{2}\.\d{3}$/.test(value)
const isDateValid = value => value instanceof Date && !isNaN(value)
const mergeDateWithTime = ({ time, date }) => {
  const [hours, minutes] = time.split(':')

  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}
const getTimeFromDate = value => `${value.toTimeString().split(' ')[0]}.000`

module.exports = {
  isTimeValid,
  isDateValid,
  mergeDateWithTime,
  getTimeFromDate
}
