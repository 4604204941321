'use strict'
const {
  selectCurrentRecord,
  selectCurrentRecordIndex
} = require('../../dataset-controller/rootReducer')
const get_ = require('lodash/get')
const values_ = require('lodash/values')
const isEmpty_ = require('lodash/isEmpty')
const forEach_ = require('lodash/forEach')
const reduce_ = require('lodash/reduce')
const { getFieldValue, convertValue, formatValue } = require('./helpers')
const baseAdapter = require('./baseAdapter')

module.exports = ({
  getState,
  wixSdk,
  errorReporter,
  getFieldType,
  databindingVerboseReporter,
  wixFormatter
}) => {
  const logVerboseForBinding = (component, connectionConfig) => {
    const { properties } = connectionConfig
    const bindingDescription = {}

    forEach_(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })
    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  const currentRecordModified = ({
    component,
    connectionConfig: { properties }
  }) => {
    const record = selectCurrentRecord(getState())
    const currentIndex = selectCurrentRecordIndex(getState())
    component.markers[currentIndex] = createMarkerFromRecord(record, properties)
    component.setCenter(get_(component, ['markers', currentIndex, 'location']))
  }

  const setCurrentCenterMarkerOfMap = ({ component }) => {
    const currentIndex = selectCurrentRecordIndex(getState())
    component.setCenter(get_(component, ['markers', currentIndex, 'location']))
  }

  const createMarkerFromRecord = (record, properties = []) =>
    reduce_(
      properties,
      (marker, { fieldName, format }, propName) => {
        const fieldValue = getFieldValue(record, fieldName)
        if (propName === 'address') {
          return Object.assign(marker, {
            address: get_(fieldValue, 'formatted'),
            location: get_(fieldValue, 'location')
          })
        } else if (propName === 'link' && isEmpty_(fieldValue)) {
          return marker
        }
        const fieldType = getFieldType(fieldName).getOrElse('')
        const formattedValue = formatValue(fieldValue, wixFormatter, {
          format,
          wixSdk
        })
        const convertedValue = convertValue(formattedValue, {
          fieldType,
          shouldConvertToString: true
        })
        return Object.assign(marker, {
          [propName]: convertedValue
        })
      },
      {}
    )

  const updateComponentFromRecords = async (
    { connectionConfig: { properties }, component },
    actions
  ) => {
    try {
      const { items: records } = await actions.fetchCurrentItems(getState())
      component.markers = records.map(record =>
        createMarkerFromRecord(record, properties)
      )
    } catch (e) {
      errorReporter(`Failed setting markers:`, e)
    }
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig }) {
      return values_(connectionConfig).find(
        configValue => !isEmpty_(configValue)
      )
    },

    bindToComponent({ connectionConfig, component }) {
      logVerboseForBinding(component, connectionConfig)
    },

    currentRecordModified,
    recordSetLoaded: updateComponentFromRecords,
    currentViewChanged: updateComponentFromRecords,

    currentIndexChanged(componentAdapterContext) {
      setCurrentCenterMarkerOfMap(componentAdapterContext)
    }
  }
}
