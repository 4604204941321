'use strict'

const includes_ = require('lodash/includes')
const get_ = require('lodash/get')
const uniqBy_ = require('lodash/uniqBy')
const noop_ = require('lodash/noop')
const { TraceType } = require('../logger/traceType')

const {
  isDatasetReady,
  hasCurrentRecord,
  selectCurrentRecordIndex,
  isReadOnly,
  isEditable,
  getPaginationData,
  selectNextDynamicPageUrl,
  selectPreviousDynamicPageUrl
} = require('../dataset-controller/rootReducer')

const createBreadcrumbMessage = require('../helpers/createBreadcrumbMessage')

const isInputComponent = require('../helpers/isInputComponent')
const {
  DROPDOWN_OPTIONS_ROLE,
  FILTER_INPUT_ROLE,
  BUTTON_ROLE,
  ICON_BUTTON_ROLE,
  STYLABLE_BUTTON_ROLE,
  SELECTION_TAGS_OPTIONS_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')
const LINKABLE_DISABLEABLE_COMPONENTS = [
  BUTTON_ROLE,
  ICON_BUTTON_ROLE,
  STYLABLE_BUTTON_ROLE
]

const undisabableRoles = [
  DROPDOWN_OPTIONS_ROLE,
  FILTER_INPUT_ROLE,
  SELECTION_TAGS_OPTIONS_ROLE
]

const getComponentsFromContexts = cacs => cacs.map(({ component }) => component)

const getDisableableInputComponents = cacs =>
  getComponentsFromContexts(
    cacs.filter(
      ({ role, component }) =>
        !undisabableRoles.includes(role) && isInputComponent(component)
    )
  )

const getDisableableLinkedComponents = (cacs, disableableActions) =>
  getComponentsFromContexts(
    cacs
      .filter(({ role }) => includes_(LINKABLE_DISABLEABLE_COMPONENTS, role))
      .filter(({ connectionConfig }) =>
        includes_(
          disableableActions,
          get_(connectionConfig, 'events.onClick.action')
        )
      )
  )

const getDisableableComponents = (cacs, disableableActions) => {
  const uniqueDisableableComponentContexts = uniqBy_(
    cacs.filter(({ component }) => component.enabled),
    ({ component }) => component.uniqueId
  )

  return {
    inputComponents: getDisableableInputComponents(
      uniqueDisableableComponentContexts
    ),
    linkedComponents: getDisableableLinkedComponents(
      uniqueDisableableComponentContexts,
      disableableActions
    )
  }
}

const updateComponentEnabledState = (
  comp,
  shouldBeEnabled,
  logger,
  datasetId
) => {
  if (comp.enabled !== shouldBeEnabled) {
    shouldBeEnabled ? comp.enable() : comp.disable()
    logger.trace(
      TraceType.Breadcrumb({
        category: 'components',
        message: createBreadcrumbMessage(
          `${comp.uniqueId} changed to ${
            shouldBeEnabled ? 'enabled' : 'disabled'
          }`,
          datasetId
        )
      })
    )
  }
}

const getSyncComponentsWithStateSubscriber = ({
  getState,
  inputComponents,
  linkedComponents,
  datasetId,
  logger,
  shouldEnableLinkedComponent
}) => () => {
  const state = getState()
  if (!isDatasetReady(state)) {
    return
  }

  const shouldInputComponentsBeEnabled = isEditable(state)
  inputComponents.forEach(component => {
    updateComponentEnabledState(
      component,
      shouldInputComponentsBeEnabled,
      logger,
      datasetId
    )
  })

  linkedComponents.forEach(component => {
    const action = component.connectionConfig.events.onClick.action
    const shouldBeEnabled = shouldEnableLinkedComponent(action, state)
    updateComponentEnabledState(component, shouldBeEnabled, logger, datasetId)
  })
}

const syncEnabledStateForComponentsNotDisabledByUser = (
  { getState, subscribe },
  componentAdapterContexts,
  logger,
  datasetId,
  recordStore
) => {
  const getMatchingRecordCount = () =>
    recordStore().fold(
      () => 0,
      service => service.getMatchingRecordCount()
    )

  const hasNextRecord = state =>
    hasCurrentRecord(state) &&
    selectCurrentRecordIndex(state) < getMatchingRecordCount() - 1

  const hasPreviousRecord = state =>
    hasCurrentRecord(state) && selectCurrentRecordIndex(state) > 0

  const hasPreviousPage = state => getPaginationData(state).offset > 0

  const hasNextPage = state => {
    const { offset, size, numPagesToShow } = getPaginationData(state)
    return size * numPagesToShow + offset < getMatchingRecordCount()
  }

  const shouldEnableLinkedComponent = (action, state) =>
    shouldEnableByAction[action](state)
  const shouldEnableByAction = {
    new: state => !isReadOnly(state),
    save: isEditable,
    revert: isEditable,
    remove: isEditable,
    next: hasNextRecord,
    previous: hasPreviousRecord,
    nextPage: hasNextPage,
    previousPage: hasPreviousPage,
    nextDynamicPage: state => selectNextDynamicPageUrl(state).hasUrl(),
    previousDynamicPage: state => selectPreviousDynamicPageUrl(state).hasUrl(),
    loadMore: hasNextPage
  }

  const disableableActions = Object.keys(shouldEnableByAction)

  const { inputComponents, linkedComponents } = getDisableableComponents(
    componentAdapterContexts,
    disableableActions
  )

  const quantityOfDisableableComponents =
    inputComponents.length + linkedComponents.length

  const unsubscribe = quantityOfDisableableComponents
    ? subscribe(
        getSyncComponentsWithStateSubscriber({
          getState,
          inputComponents,
          linkedComponents,
          datasetId,
          logger,
          shouldEnableLinkedComponent
        })
      )
    : noop_

  return unsubscribe
}

module.exports = (
  store,
  componentAdapterContexts,
  logger,
  datasetId,
  recordStore
) => {
  return syncEnabledStateForComponentsNotDisabledByUser(
    store,
    componentAdapterContexts,
    logger,
    datasetId,
    recordStore
  )
}
