const {
  FieldType: { address }
} = require('@wix/wix-data-schema-types')
const isNil_ = require('lodash/isNil')
const flow_ = require('lodash/flow')
const convertValueToString = require('../../../helpers/convertValueToString')
const convertToStaticLinkIfMediaItemUri = require('./convertToStaticLinkIfMediaItemUri')

module.exports = (
  initValue,
  {
    fieldType,
    shouldConvertToString,
    shouldConvertMediaItemUriToStaticUrl,
    mediaItemUtils
  }
) => {
  let value

  if (!isNil_(initValue)) {
    switch (fieldType) {
      case address:
        const { formatted } = initValue
        value = formatted
        break
      default:
        value = initValue
    }
  }

  const conversionFlow = [
    ...(shouldConvertToString ? [convertValueToString] : []),
    ...(shouldConvertMediaItemUriToStaticUrl
      ? [value => convertToStaticLinkIfMediaItemUri({ value, mediaItemUtils })]
      : [])
  ]

  return flow_(conversionFlow)(value)
}
