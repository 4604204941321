'use strict'

const { addComponentEventListener } = require('./helpers')
const { selectCurrentRecord } = require('../../dataset-controller/rootReducer')
const { traceCreators } = require('../../logger')
const performance = require('../../helpers/performance')
const baseAdapter = require('./baseAdapter')

const adapter = ({
  appLogger,
  getState,
  controllerFactory,
  controllerStore,
  applicationCodeZone,
  databindingVerboseReporter
}) => {
  const itemReadyPromises = []

  const itemReady = (compId, compUniqueId) => (scoped$w, itemData, index) => {
    appLogger.traceSync(traceCreators.repeaterItemReady(index), () => {
      const scopeId = { componentId: compUniqueId, itemId: itemData._id }
      const controller = controllerFactory.createFixedItemController(
        scopeId,
        itemData,
        compId,
        scoped$w.scoped
      )
      controllerStore.setController(scopeId, controller)
      const pageReadyPromise = controller.pageReady()
      itemReadyPromises.push(pageReadyPromise)
    })
  }

  const itemRemoved = compUniqueId => itemData => {
    const scopeId = { componentId: compUniqueId, itemId: itemData._id }
    controllerStore.removeController(scopeId)
  }

  const refreshView = traceAction => async ({ component }, actions) => {
    return appLogger.traceAsync(traceAction, async () => {
      const { items } = await actions.fetchCurrentItems(getState())

      databindingVerboseReporter.logValue({
        component,
        valueDescription: { data: items }
      })
      appLogger.traceSync(traceCreators.repeaterSetData(), () => {
        performance.mark('repeater.refreshView.beforeSetDataItems')
        component.data = items
      })
      await Promise.all(itemReadyPromises)
      performance.measure(
        'repeater.refreshView.renderItemsTime',
        'repeater.refreshView.beforeSetDataItems'
      )
      itemReadyPromises.splice(0)
    })
  }

  return {
    ...baseAdapter,

    bindToComponent({ component }, actions, api) {
      const { id: compId, uniqueId: compUniqueId } = component
      addComponentEventListener(
        component,
        'onItemReady',
        itemReady(compId, compUniqueId),
        applicationCodeZone
      )
      addComponentEventListener(
        component,
        'onItemRemoved',
        itemRemoved(compUniqueId),
        applicationCodeZone
      )

      databindingVerboseReporter.logBinding({ component })
    },

    currentRecordModified({ component }, actions, updatedFields) {
      const updatedItem = selectCurrentRecord(getState())
      if (component.data && component.data.length > 0) {
        const existingItems = component.data

        const newItems = existingItems.map(existingItem =>
          existingItem._id === updatedItem._id ? updatedItem : existingItem
        )
        component.data = newItems
      }
    },

    recordSetLoaded: refreshView(traceCreators.repeaterRecordSetLoaded()),
    currentViewChanged: refreshView(traceCreators.repeaterCurrentViewChanged())
  }
}

module.exports = adapter
