const range_ = require('lodash/range')
const { constructUrl } = require('@wix/dbsm-common/src/dynamic-pages/urlUtils')

const ASCENDING = 'asc'
const DESCENDING = 'desc'

const appendQueryWithSort = ({
  getPreviousUrl,
  sort,
  dynamicUrlPatternFieldsValues,
  query,
  indexField
}) => {
  const shouldSortInAscendingOrder = sortDirection =>
    (sortDirection === ASCENDING && !getPreviousUrl) ||
    (sortDirection === DESCENDING && getPreviousUrl)

  let queryWithSort = query

  Object.keys(sort).forEach(key => {
    queryWithSort = shouldSortInAscendingOrder(sort[key])
      ? queryWithSort.ascending(key)
      : queryWithSort.descending(key)
  })

  queryWithSort = shouldSortInAscendingOrder(sort[indexField])
    ? queryWithSort.gt(indexField, dynamicUrlPatternFieldsValues[indexField])
    : queryWithSort.lt(indexField, dynamicUrlPatternFieldsValues[indexField])

  return queryWithSort
}

const getSiblingPage = async ({
  wixDataProxy,
  dynamicPagesData,
  collectionName,
  getPreviousUrl
}) => {
  const {
    dynamicUrl,
    userDefinedFilter,
    dynamicUrlPatternFieldsValues,
    sort,
    sortFields,
    patternFields
  } = dynamicPagesData || {}
  if (dynamicUrl == null || !patternFields.length) {
    return null
  }

  const joinedQueries = recursiveSiblingDynamicPage().reduce((result, query) =>
    result.or(query)
  )

  const { items } = await joinedQueries.find()
  const firstRecord = items.length > 0 ? items[0] : null
  return firstRecord ? constructUrl(firstRecord, dynamicUrl) : null

  function recursiveSiblingDynamicPage(currentFieldIndex) {
    if (currentFieldIndex === undefined)
      currentFieldIndex = sortFields.length - 1
    if (currentFieldIndex === -1) return []

    const indexField = sortFields[currentFieldIndex]

    let query = wixDataProxy
      .query(collectionName)
      .setFilterModel(userDefinedFilter)

    query = appendQueryWithSort({
      getPreviousUrl,
      sort,
      dynamicUrlPatternFieldsValues,
      query,
      indexField
    })

    range_(currentFieldIndex).forEach(
      i =>
        (query = query.eq(
          sortFields[i],
          dynamicUrlPatternFieldsValues[sortFields[i]]
        ))
    )

    return [query.limit(1)].concat(
      recursiveSiblingDynamicPage(currentFieldIndex - 1)
    )
  }
}

module.exports = ({ wixDataProxy, dynamicPagesData, collectionName }) => ({
  getNextDynamicPageUrl: () =>
    getSiblingPage({
      wixDataProxy,
      dynamicPagesData,
      collectionName,
      getPreviousUrl: false
    }),
  getPreviousDynamicPageUrl: () =>
    getSiblingPage({
      wixDataProxy,
      dynamicPagesData,
      collectionName,
      getPreviousUrl: true
    })
})
