'use strict'
const componentTypes = require('@wix/dbsm-common/src/componentTypes')
const isRecord = require('./isRecord')

const setPropAtPath = (component, propPath, valueToSet) => {
  const propNames = propPath.split('.')

  propNames.reduce((objToSet, propName, currIndex) => {
    if (currIndex < propNames.length - 1) {
      return objToSet[propName]
    }
    objToSet[propName] = valueToSet
  }, component)
}

const isValueReference = (value, fieldType) =>
  fieldType === 'reference' && isRecord(value)

const isComponentWriteOnly = ({ type }) =>
  type === componentTypes.SignatureInput

module.exports = ({ component, fieldType, propPath, value: _value }) => {
  const value = isValueReference(_value, fieldType) ? _value._id : _value

  if (isComponentWriteOnly(component)) {
    if (!value) {
      component.clear()
    }
    return
  }
  //set_ doesn't work if oldValue and newValue are the same.
  setPropAtPath(component, propPath, value)
}
